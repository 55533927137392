<template>
    <div class="app-container" style="margin-bottom: 20px">
      <!-- -------万恶分割线------- -->
      <!-- 添加或编辑类别 -->
      <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
        <!-- 表单 -->
        <el-form
          ref="perForm"
          :model="formData"
          :rules="rules"
          label-width="120px"
        >
        <el-form-item label="用户名称" prop="userName">
          <el-input
            v-model="formData.userName"
            placeholder="请输入用户名称"
            style="width: 50%"
       
          />
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input
            v-model="formData.tel"
            placeholder="请输入联系电话"
            style="width: 50%"
       
          />
        </el-form-item>
        <el-form-item label="行业信息" prop="tradeMsg">
          <el-input
            v-model="formData.tradeMsg"
            placeholder="请输入行业信息"
            style="width: 50%"
       
          />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="formData.remark"
            placeholder="请输入备注"
            style="width: 50%"
       
          />
        </el-form-item>
     
        </el-form>
        <el-row slot="footer" type="flex" justify="center">
          <el-col :span="6">
            <el-button size="small" type="primary" @click="btnOK">
              确定
            </el-button>
            <el-button size="small" @click="btnCancel">取消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
  
      <div style="margin-bottom: 20px">
        <el-row>
          <el-col :span="12" align="left">
            <el-button class="el-icon-edit" size="small" type="primary" @click="goback"
              >返回</el-button> 
            </el-col>
            <el-col :span="12" align="right">
          <el-button size="small" type="danger" @click="exportData">导出</el-button>
        </el-col>
        </el-row>
      </div>
  
      <!-- 主体内容 -->
     
      <div class="tab">
        <el-table
          :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
          :data="list"
          border
          style="width: 100%"
          ref="tableData"
          class="emp_table"
          v-if="list"
        > <el-table-column
          align="center"
          prop="userName"
          label="用户名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="tel"
          label="联系电话"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="tradeMsg"
          label="行业信息"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
          :show-overflow-tooltip="true"
        /> 
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>
          <el-table-column align="center" label="操作" width="300">
            <template slot-scope="scope">
                <el-button type="text" size="small" @click="updateSignInfo(scope.row)">
                编辑
              </el-button>
              <el-button type="text" size="small" @click="del(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pageSize"
          @pagination="getSignInfoList"
        />
      </div>
    </div>
  </template>
    <script>
  
  import {
    updateSignInfo,
    getSignInfoList,
    delSignInfo,
  } from '../../request/http'
  
  export default {
    data() {
      return {
     
        list: '', //列表
        total: 0, //总数
       
        activityId: '',
        queryParams: {
          page: 1,
          pageSize: 10
        },
        title: '',
        showDialog: false,
      
        formData: {
            userName:"",
            tel:"",
            tradeMsg:"",

        },
      
        rules: {
            userName: [
          { required: true, message: '用户名称不能为空', trigger: 'blur' }
        ],

        tel: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }
        ],
        tradeMsg: [
            { required: true, message: '行业信息不能为空', trigger: 'blur' }
          ],
         
        }
      }
    },
    created() {
      this.activityId = this.$route.query.activityId
      
     
    },
    mounted() {
      this.getSignInfoList()
    },
  
    methods: {
      //获取列表
      async getSignInfoList() {
        let { data, count } = await getSignInfoList({
            activityId: this.activityId
        })
        this.list = data
        this.total = count
      },
  
         //编辑
      updateSignInfo(val){
       this.title ="编辑用户报名信息"
      this.formData={
        recordId:val.recordId,
        userName:val.userName,
            tel:val.tel,
            tradeMsg:val.tradeMsg,
      } 
       this.showDialog =true
      },
      
  
    
      // 删除类别
      del(val) {
        console.log(val)
        let id = val.recordId
        this.$confirm('此操作将删除报名用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            let res = await delSignInfo({ recordId:id })
            // console.log(res);
            res.code == 0 &&
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            const totalPage = Math.ceil(
              (this.total - 1) / this.queryParams.pageSize
            )
            this.queryParams.page =
              this.queryParams.page > totalPage
                ? totalPage
                : this.queryParams.page
            this.queryParams.page =
              this.queryParams.page < 1 ? 1 : this.queryParams.page
            this.getSignInfoList()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      },
      // 返回上一级页面
      goback() {
        this.$router.go(-1)
      },
      // 确认
      async btnOK() {
        this.$refs.perForm.validate().then(async () => {
          let { code, msg } = await updateSignInfo(this.formData)
          console.log(code, msg)
          if (code == 0) {
            this.$message.success('编辑成功')
          } else {
            console.log(msg)
            this.$message({
              type: 'error',
              message: msg
            })
          }
          this.getSignInfoList()
          this.showDialog = false
        })
      },
  
      //弹窗取消函数
      btnCancel() {
       
       this.formData =  {
        },
  
        this.$refs.perForm.resetFields()
        this.showDialog = false
      },
       // 导出
       exportData() {
      const headers = {
        '用户名称': 'userName',
        '联系电话': 'tel',
        '行业信息':'tradeMsg',
        '备注':'remark'
       
      }
      import('@/vendor/Export2Excel').then(async excel => {
        const a = await getSignInfoList({ activityId: this.activityId, page: 1, pageSize: 10000})
        const rows = a.data
        console.log(rows)
     
        var data = this.formatJson(headers, rows)
       

        excel.export_json_to_excel({
          header: Object.keys(headers),
          data,
          filename: '活动报名列表',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(headers, rows) {
      return rows.map(item =>
        Object.keys(headers).map(key => item[headers[key]])
      )
    },
    }
  }
  </script>
    
    <style>
    
  </style>